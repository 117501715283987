import * as ACTION_TYPES from './usersActionTypes';

export const usersReducerInitialState = {
	listUsers: {
		data: [],
		meta:null,
		isFetching: false,
		hasError: false,
		error: {},
	},
	getUserByMag: {
		data: {},
		isFetching: false,
		hasError: false,
    exists:true,// true == user  exists in SSO
		error: {}
	},
	restoreUser: {
		data: {},
		isFetching: false,
		hasError: false,
		error: {}
	},
	changePassword: {
		data: {},
		isFetching: false,
		hasError: false,
		error: {}
	},
};

export const usersReducer = (state = usersReducerInitialState, action) => {
	switch (action.type) {

		// List users filtered action handlers
		case ACTION_TYPES.USERS_REQUEST: {
			return {
				...state,
				listUsers: {
					...state.listUsers,
					isFetching: true,
					hasError: false
				}
			};
		}
		case ACTION_TYPES.USERS_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				listUsers: {
					...state.listUsers,
					isFetching: false,
					hasError: false,
					error: usersReducerInitialState?.listUsers?.error,
					data:data.data,
					meta:data.meta

				}
			};
		}
		case ACTION_TYPES.USERS_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				listUsers: {
					...state.listUsers,
					isFetching: false,
					hasError: true,
					data: usersReducerInitialState?.listUsers,
					meta:usersReducerInitialState?.listUsers,
					// error
				}
			};
		}
		case ACTION_TYPES.CLEAR_USERS_REQUEST: {
			return {
				...state,
				listUsers: usersReducerInitialState?.listUsers
			};
		}
		// END List users filtered action handlers

		//START user by mag action handlers
		case ACTION_TYPES.USERS_BY_MAG_REQUEST: {
			return {
				...state,
				getUserByMag: {
					...state.getUserByMag,
					isFetching: true,
					hasError: false
				}
			};
		}
		case ACTION_TYPES.USERS_BY_MAG_REQUEST_SUCCESS: {
			const { data } = action.payload;

			return {
				...state,
				getUserByMag: {
					...state.getUserByMag,
					isFetching: false,
					hasError: false,
					error: usersReducerInitialState?.getUserByMag?.error,
					data: data
				}
			};
		}
		case ACTION_TYPES.USERS_BY_MAG_REQUEST_EXISTS_SUCCESS: {
			const {  data } = action.payload;
			return {
				...state,
				getUserByMag: {
					...state.getUserByMag,
					isFetching: false,
					hasError: false,
          exists:data,
					error: usersReducerInitialState?.getUserByMag?.error,

				}
			};
		}
		case ACTION_TYPES.USERS_BY_MAG_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				getUserByMag: {
					...state.getUserByMag,
					isFetching: false,
					hasError: true,
          exists:false,
					data: { ...state?.getUserByMag?.data },
					// data: { },
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_USERS_BY_MAG_REQUEST: {
			return {
				...state,
				getUserByMag: usersReducerInitialState?.getUserByMag
			};
		}
		// END List users filtered action handlers

		//START user by mag action handlers
		case ACTION_TYPES.RESTORE_USER_REQUEST: {
			return {
				...state,
				restoreUser: {
					...state.restoreUser,
					isFetching: true,
					hasError: false
				}
			};
		}
		case ACTION_TYPES.RESTORE_USER_REQUEST_SUCCESS: {
			const { data } = action.payload;

			return {
				...state,
				restoreUser: {
					...state.restoreUser,
					isFetching: false,
					hasError: false,
					error: usersReducerInitialState?.restoreUser?.error,
					data: data
				}
			};
		}
		case ACTION_TYPES.RESTORE_USER_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				restoreUser: {
					...state.restoreUser,
					isFetching: false,
					hasError: true,
					data: { ...state?.restoreUser?.data },
					// error
				}
			};
		}
		// END List users filtered action handlers

		//START user by mag action handlers
		case ACTION_TYPES.PUT_CHANGE_PASSWORD_REQUEST: {
			return {
				...state,
				changePassword: {
					...state.changePassword,
					isFetching: true,
					hasError: false
				}
			};
		}
		case ACTION_TYPES.PUT_CHANGE_PASSWORD_REQUEST_SUCCESS: {
			const { data } = action.payload;

			return {
				...state,
				changePassword: {
					...state.changePassword,
					isFetching: false,
					hasError: false,
					error: usersReducerInitialState?.changePassword?.error,
					data: data
				}
			};
		}
		case ACTION_TYPES.PUT_CHANGE_PASSWORD_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				changePassword: {
					...state.changePassword,
					isFetching: false,
					hasError: true,
					data: { ...state?.changePassword?.data },
					// error
				}
			};
		}
		// END List users filtered action handlers
		//START user by mag action handlers
		case ACTION_TYPES.POST_USERS_REQUEST_SUCCESS: {
      const { data } = action.payload;

      let index=state?.listUsers?.data?.findIndex(element=>element.id === data.data.id);
      const newList=[...state.listUsers.data]
      newList[index]=data.data;

      // state?.listUsers[index]=data;
			return {
				...state,
				listUsers: {
					...state.listUsers,
          data: newList,
					isFetching: false,
					hasError: false
				}
			};
		}
		// END List users filtered action handlers
		default: {
			return state;
		}
	}
};

export default usersReducer;

export const getUsersReducer = state => state.usersReducer;

// Getters list candidates filtered
export const getListUsers = state => getUsersReducer(state)?.listUsers;
export const getListUsersData = state => getListUsers(state)?.data;
export const getListUsersMetaData = state => getListUsers(state)?.meta;
export const getListUsersIsFetching = state => getListUsers(state)?.isFetching;
export const getListUsersHasError = state => getListUsers(state)?.hasError;
export const getListUsersError = state => getListUsers(state)?.error;

// Getters user by mag filtered
export const getUserByMag = state => getUsersReducer(state)?.getUserByMag;
export const getUserByMagData = state => getUsersReducer(state)?.getUserByMag?.data;
export const getUserByMagIsFetching = state => getUsersReducer(state)?.getUserByMag.isFetching;
export const getUserByMagExists = state => getUsersReducer(state)?.getUserByMag.exists;
export const getUserByMagHasError = state => getUsersReducer(state)?.getUserByMag.hasError;
export const getUserByMagError = state => getUsersReducer(state)?.getUserByMag.error;

// Getters user by mag filtered
export const postChangePassword = state => getUsersReducer(state)?.changePassword;
export const postChangePasswordData = state => postChangePassword(state)?.data;
export const postChangePasswordIsFetching = state => postChangePassword(state).isFetching;



