import * as ACTION_TYPES from './plantsActionTypes'

export const plantsReducerInitialState = {
  listPlants: {
    data: [],
    meta: { current_page: 0, last_page: 1 },
    isFetching: false,
    hasError: false,
    error: {},
  },
  findPlant: {
    data: undefined,
    isFetching: false,
    hasError: false,
    error: {},
  },
  createPlant: {
    data: undefined,
    isFetching: false,
    hasError: false,
    error: {},
  },
}

export const plantsReducer = (state = plantsReducerInitialState, action) => {
  switch (action.type) {
    // List plants filtered action handlers
    case ACTION_TYPES.PLANTS_REQUEST: {
      return {
        ...state,
        listPlants: {
          ...state.listPlants,
          isFetching: true,
          hasError: false,
        },
      }
    }
    case ACTION_TYPES.PLANTS_REQUEST_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        listPlants: {
          ...state?.listPlants,
          isFetching: false,
          hasError: false,
          error: plantsReducerInitialState?.listPlants?.error,
          data: data?.data ?? [],
          meta: data?.meta ?? plantsReducerInitialState?.listPlants?.meta,
        },
      }
    }
    case ACTION_TYPES.PLANTS_REQUEST_FAILURE: {
      const { error } = action.payload
      return {
        ...state,
        listPlants: {
          ...state.listPlants,
          isFetching: false,
          hasError: true,
          data: plantsReducerInitialState?.listPlants?.data,
          // error,
        },
      }
    }
    case ACTION_TYPES.CLEAR_PLANTS_REQUEST: {
      return {
        ...state,
        listPlants: plantsReducerInitialState?.listPlants,
      }
    }
    // END List plants filtered action handlers

    //Start Find Plant filter action handles
    case ACTION_TYPES.FIND_PLANT_REQUEST: {
      return {
        ...state,
        findPlant: {
          ...state.findPlant,
          isFetching: true,
          hasError: false,
        },
      }
    }
    case ACTION_TYPES.FIND_PLANT_REQUEST_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        findPlant: {
          ...state.findPlant,
          isFetching: false,
          hasError: false,
          error: plantsReducerInitialState?.findPlant?.error,
          data,
        },
      }
    }
    case ACTION_TYPES.FIND_PLANT_REQUEST_FAILURE: {
      const { error } = action.payload
      return {
        ...state,
        findPlant: {
          ...state.findPlant,
          isFetching: false,
          hasError: true,
          data: plantsReducerInitialState?.findPlant?.data,
          // error,
        },
      }
    }
    case ACTION_TYPES.CLEAR_FIND_PLANTS_REQUEST: {
      return {
        ...state,
        findPlant: plantsReducerInitialState?.findPlant,
      }
    }

    // END Find plants filtered action handlers

    //Start create Plant filter action handles
    case ACTION_TYPES.CREATE_PLANT_REQUEST: {
      return {
        ...state,
        createPlant: {
          ...state.createPlant,
          isFetching: true,
          hasError: false,
        },
      }
    }
    case ACTION_TYPES.CREATE_PLANT_REQUEST_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        createPlant: {
          ...state.createPlant,
          isFetching: false,
          hasError: false,
          error: plantsReducerInitialState?.createPlant?.error,
          data,
        },
      }
    }
    case ACTION_TYPES.CREATE_PLANT_REQUEST_FAILURE: {
      const { error } = action.payload
      return {
        ...state,
        createPlant: {
          ...state.createPlant,
          isFetching: false,
          hasError: true,
          data: plantsReducerInitialState?.createPlant?.data,
          // error,
        },
      }
    }

    // END Find plants filtered action handlers
    default: {
      return state
    }
  }
}

export default plantsReducer

export const getPlantsReducer = (state) => state.plantsReducer

// Getters list candidates filtered
export const getListPlants = (state) => getPlantsReducer(state)?.listPlants
export const getListPlantsData = (state) => getListPlants(state)?.data
export const getListPlantsMetaData = (state) => getListPlants(state)?.meta
export const getListPlantsIsFetching = (state) => getListPlants(state)?.isFetching
export const getListPlantsHasError = (state) => getListPlants(state)?.hasError
export const getListPlantsError = (state) => getListPlants(state)?.error

//Getters for find  element
export const getFindPlant = (state) => getPlantsReducer(state)?.findPlant
export const getFindPlantData = (state) => getFindPlant(state)?.data
export const getFindPlantIsFetching = (state) => getFindPlant(state)?.isFetching
export const getFindPlantHasError = (state) => getFindPlant(state)?.hasError
export const getFindPlantError = (state) => getFindPlant(state)?.error

//Getters for find  element
export const getCreatePlant = (state) => getPlantsReducer(state)?.createPlant
export const getCreatePlantData = (state) => getCreatePlant(state)?.data
export const getCreatePlantIsFetching = (state) => getCreatePlant(state)?.isFetching
export const getCreatePlantHasError = (state) => getCreatePlant(state)?.hasError
export const getCreatePlantError = (state) => getCreatePlant(state)?.error
